import React, { Component } from "react";
import { withRouter } from "react-router";
import { Segment } from "semantic-ui-react";
import moment from 'moment';


export const SolsysAuthReactContext = React.createContext();

class SolsysAuthReact extends Component {
  state = {
    isAuthenticated: false,
    isFetchingAccessToken: false,
    isFetchingAuthorizationCode: false,
    isRefreshingToken: false,
    didAccessTokenSucceed: false,
    isFetchingUser: false,
    user: null,
  }

  checkAuthenticationIntervalObject = null;
  checkAuthenticationInterval = 30 * 1000 // milliseconds

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";
  serverUrl = process.env.REACT_APP_SOLSYSAUTH_API_URL || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_API_URL";
  redirectURI = process.env.REACT_APP_SOLSYSAUTH_REDIRECT_URI || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_REDIRECT_URI";
  clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";
  clientSecret = process.env.REACT_APP_SOLSYSAUTH_CLIENT_SECRET || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_SECRET";
  cookieDomain = process.env.REACT_APP_SOLSYSAUTH_COOKIE_DOMAIN || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_COOKIE_DOMAIN";
  
  get cookies() {
    return document.cookie.split(';').map(v => v.trim())
  }
  
  get isAuthenticated() {
    return this.cookies.find(cookie => cookie.startsWith("tssat_cookie_" + this.clientId))
      && this.accessExpiration.isAfter();
  }
  
  get accessExpiration() {
    return new moment(this.getLocalStorage('tssat_' + this.clientId) || null);
  }
  
  get refreshExpiration() {
    return new moment(this.getLocalStorage('tssrt_' + this.clientId) || null);
  }
  
  get hasReauth() {
    return this.refreshExpiration.isAfter()
  }

  componentDidMount() {

    // Checks for authorization code in redirect
    let searchParams = new URLSearchParams(this.props.location.search);
    let isAuthorizationCodePresent = searchParams.has("code");

    if(this.isAuthenticated) {
      // already previously authorized
      this.setState({isAuthenticated: true})
      if (!this.props.clientOnly) {
        this.getUser();
      }
      if (this.hasReauth) this.setupReauth();
    } else if(isAuthorizationCodePresent) {
      // get accessToken from redirect
      let code = searchParams.get("code");
      let state = searchParams.get("state");
      let jsonData = atob(state)
      let locationState;
      try {
        locationState = JSON.parse(jsonData);
      } catch (err) {
        console.error(err);
      }
      let failedReauth = false;
      if (locationState) {
        if (locationState.e === '1') failedReauth = true
        this.props.history.replace(locationState.p + locationState.s + locationState.h);
      }
      this.getAccessToken(code, failedReauth);
    } else if (this.hasReauth) {
      // get access with refresh
      this.setupReauth();
    } else {
      // get an auth code
      let locationState = btoa(JSON.stringify({
        p: this.props.location.pathname,
        s: this.props.location.search,
        h: this.props.location.hash
      }));

      if(this.props.clientOnly) {
        this.getAuthorizationCode(locationState);
      } else {
        this.sendToLogin();
      }
    }
  }

  setLocalStorage = (key, value) => {
    return localStorage.setItem(btoa(key), btoa(value));
  }

  getLocalStorage = (key) => {
    let value = localStorage.getItem(btoa(key));
    return value && atob(value);
  }

  removeLocalStorage = (key) => {
    return localStorage.removeItem(btoa(key));
  }

  sendToLogin = (options = {}) => {
    let locationState = btoa(JSON.stringify({
      p: this.props.location.pathname,
      s: this.props.location.search,
      h: this.props.location.hash,
      ...options.failedReauth && {e: '1'}
    }));
    let location = `${this.serverUrl}/login?client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectURI || "")}&state=${locationState}`;
    if (options.logout) location += "&logout=1";
    if (options.failedReauth) {
      var a = document.createElement('a');
      a.href = location;
      a.setAttribute('target', '_blank');
      a.click();
      alert('Authentication has failed. READ THE FOLLOWING CAREFULLY OR ASK FOR ASSISTANCE!\r\rPlease check for a blocked popup or simply open a new tab and log in, then return to this tab.\r\rFailure to log in before dismissing this could result in loss of unsaved work.\r\rDismiss this to continue after logging back in.');
    } else {
      window.location = location
    }
  }

  setupAccessToken = (accessToken, expiresIn) => {
    document.cookie = `tssat_cookie_${this.clientId}=${accessToken} ;max-age=${expiresIn} ;domain=${this.cookieDomain} ;path=/`;
    if (!this.props.clientOnly) {
      this.getUser();
    }
    this.setState({ isAuthenticated: true });
  }

  setupReauth = (refreshExpiration, accessExpiration) => {
    if (refreshExpiration) this.setLocalStorage('tssrt_' + this.clientId, refreshExpiration.format())
    if (accessExpiration) this.setLocalStorage('tssat_' + this.clientId, accessExpiration.format())
    if (this.checkAuthenticationIntervalObject) clearTimeout(this.checkAuthenticationIntervalObject);
    this.checkAuthenticationIntervalObject = setInterval(this.checkAuthentication, this.checkAuthenticationInterval)
    this.checkAuthentication()
  }

  getAuthorizationCode = (locationState) => {
    this.setState({isFetchingAuthorizationCode: true})
    let clientIdInput = document.createElement('input');
    clientIdInput.name = "client_id";
    clientIdInput.value = this.clientId;
    clientIdInput.type = "hidden";

    let redirectURIInput = document.createElement('input');
    redirectURIInput.name = "redirect_uri";
    redirectURIInput.value = this.redirectURI;
    redirectURIInput.type = "hidden";

    let stateInput = document.createElement('input');
    stateInput.name = "state";
    stateInput.value = locationState;
    stateInput.type = "hidden";

    let form = document.createElement('form');
    form.appendChild(clientIdInput);
    form.appendChild(redirectURIInput);
    form.appendChild(stateInput);
    form.method = "post";
    form.action = this.serverUrl + "/oauth/authorize";
    let rootDiv = document.getElementById('root');
    if (rootDiv) {
        rootDiv.append(form);
    }
    form.submit();
  }

  getAccessToken = (code, failedReauth = false) => {
    this.setState({isFetchingAccessToken: true})
    let auth = btoa(`${this.clientId}:${this.clientSecret}`);
    return fetch(this.serverUrl + "/oauth/token", {
      method: "POST",
      headers: {
        authorization: `Basic ${auth}`,
        "content-type": "application/x-www-form-urlencoded",
        clientId: this.clientId
      },
      credentials: 'include',
      body: `grant_type=authorization_code&redirect_uri=${this.redirectURI}&code=${code}`
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        } else {
          throw "Token request failed"
        }
      })
      .then(json => {
        this.setupAccessToken(json.access_token, json.expires_in);
        if(json.refresh_token) {
          this.setupReauth(
            new moment().add(1209599, 'seconds'),
            new moment().add(json.expires_in, 'seconds')
          )
        }
        if (failedReauth) {
          window.close()
        }
        this.setState({isFetchingAccessToken: false})
      })
      .catch(error => {
        console.error(error);
      })
  }

  refreshToken = () => {
    // console.log('refreshing token');
    this.setState({isRefreshingToken: true})
    let auth = btoa(`${this.clientId}:${this.clientSecret}`);
    fetch(this.serverUrl + "/oauth/token", {
      method: "POST",
      headers: {
        authorization: `Basic ${auth}`,
        "content-type": "application/x-www-form-urlencoded",
        clientId: this.clientId
      },
      credentials: 'include',
      body: `grant_type=refresh_token&redirect_uri=${this.redirectURI}`,
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        } else {
          console.error("Refresh Token request failed");
          console.error(response);
          throw response.text()
        }
      })
      .then(json => {
        // console.log('reauth successful');
        if(json) {
          this.setupAccessToken(json.access_token, json.expires_in);
          if(json.refresh_token) {
            this.setupReauth(
              new moment().add(1209599, 'seconds'),
              new moment().add(json.expires_in, 'seconds')
            )
          }
        }
        this.setState({isRefreshingToken: false})
      })
      .catch(error => {
        console.error('reauth failed', error);
        this.logout(true);
      })
  }
  
  checkAuthentication = () => {
    if (!this.isAuthenticated) {
      if (this.hasReauth) {
        console.log('AUTH: reauthenticating after access expiration');
        this.refreshToken()
      } else {
        console.log('AUTH: not authenticated and no reauth, logging out');
        this.logout()
      }
    } else if (
      this.accessExpiration.subtract(this.checkAuthenticationInterval, 'milliseconds').isBefore()
      && this.hasReauth
    ) {
      console.log('AUTH: reauthenticating before access expiration');
      this.refreshToken()
    }
  }

  getUser = () => {
    this.setState({isFetchingUser: true});
    return fetch(this.apiUrl + "/user", {
      method: "GET",
      headers: { clientId: this.clientId },
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(json => {
        this.setState({user: json})
      })
      .catch(error => {
        console.error(error);
        alert('Retrieving your user info failed, see the browser log for details');
      })
      .finally(() => {
        this.setState({isFetchingUser: false})
      })
  }

  logout = (failedReauth = false) => {
    document.cookie = `tssat_cookie_${this.clientId}=expired ;max-age=0 ;domain=${this.cookieDomain} ;path=/`;
    this.removeLocalStorage('tssat_' + this.clientId);
    this.removeLocalStorage('tssrt_' + this.clientId);
    if(!this.props.clientOnly) {
      this.sendToLogin({
        logout: true,
        failedReauth: failedReauth
      });
    }
  }

  render() {
    let renderChildren = !this.state.isFetchingAccessToken &&
      !this.state.isFetchingAuthorizationCode &&
      this.state.isAuthenticated &&
      (this.props.clientOnly || this.state.user !== null);

    return (
      <SolsysAuthReactContext.Provider
        value={{
          logout: this.logout,
          user: this.state.user,
        }}
      >
        {renderChildren ? this.props.children : <Segment basic loading padded="very"/>}
      </SolsysAuthReactContext.Provider>
    )
  }
};

export default withRouter(SolsysAuthReact);
